import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  Event as NavigationEvent,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { AdobeTargetService } from '@upr-web-primer/adobe-target';
import { BrowserDetectorService } from '@upr-web-primer/browser-detector';
import {
  AppDataLayerService,
  DigitalDataService,
} from '@upr-web-primer/digital-data';
import { GdsFooterService } from '@upr-web-primer/gds-footer';
import { GlobalNavService } from '@upr-web-primer/gds-global-nav';
// primer imports
import { NavigationService } from '@upr-web-primer/navigation';
import { PageLabelsResolver } from '@upr-web-primer/page-labels';
import { SpinnerService } from '@upr-web-primer/spinner';
import { TcmPictureService } from '@upr-web-primer/tcm-picture';
import { ViewportService } from '@upr-web-primer/viewport';
import { WINDOW } from '@upr-web-primer/window';
import { get, isEmpty } from 'lodash-es';
import * as moment from 'moment-timezone';
// rxjs
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { appDefault } from '../config/app-default.constant';
import { environment } from '../environments/environment';
import { ApplicationDataService } from './services/application-data.service';
import { CommonUtilityService } from './services/common-utility.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  stickyNavSub: Subscription;
  isStickyNavOpen: boolean;
  viewportSub: Subscription;
  spinnerSub: Subscription;
  adobeTargetDisabled: boolean;

  globalNavigation: boolean;
  pageLabels: any;
  subscriptions: Subscription;
  parkHours: any[] = [];
  language: string;
  country: string;
  skipToMainContentLabel: string;
  defaultLanguage: string;
  gtmUrl: string;
  digitalDataObj: any;
  digitalDataLayerObj: any;
  footerRegularData: string;
  navRegularData: string;
  quantity: any = 0;
  cartItemsLoaded = false;
  constructor(
    private router: Router,
    @Inject(WINDOW) private window: any,
    @Inject('AppDefaults') private appDefaults: any,
    private spinner: SpinnerService,
    private layoutNavigation: NavigationService,
    private viewport: ViewportService,
    private adobeTarget: AdobeTargetService,
    private zone: NgZone,
    private browserDetection: BrowserDetectorService,
    private renderer2: Renderer2,
    private pictureService: TcmPictureService,
    private common: CommonUtilityService,
    private pageLabelsService: PageLabelsResolver,
    @Inject(DOCUMENT) private document: Document,
    private digitalDataService: DigitalDataService,
    private appDataLayerService: AppDataLayerService,
    private applicationDataService: ApplicationDataService,
    private titleService: Title,
    private metaService: Meta,
    @Inject('GlobalDefaults') public globalDefault: any,
    private globalNav: GlobalNavService,
    private globalFooter: GdsFooterService,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    @Inject('AppRoutes') public appRoutes: any
  ) {
    this.adobeTargetDisabled = get(appDefaults, 'ADOBE_TARGET_DISABLED', false);

    this.pageLabelsService.get().then((pageLabels: any) => {
      this.pageLabels = pageLabels;
    });

    this.language = this.defaultLanguage;

    this.globalNavigation = get(
      this.appDefaults,
      'GLOBAL_NAVIGATION_ENABLED',
      false
    );

    // Set Default TimeZone
    if (this.appDefaults.UPR_TIME_ZONE) {
      moment.tz.setDefault(this.appDefaults.UPR_TIME_ZONE);
    }
    this.applicationDataService.readCookieData();
  }

  ngOnInit(): void {
    // this.globalFooter.init();
    this.router.events.subscribe((event: any) => {
      const currentPath: any = event?.routerEvent?.url?.split('/').pop();
      if (currentPath) {
        if (environment.isRToasterTagEnabled) this.callTrackAndRecommend();
      }
    });
    this.fetchProgressBar();
    this.titleService.setTitle(environment?.seoTitle);
    this.metaService.updateTag({
      name: this.globalDefault?.seoDescription,
      content: environment?.seoDescription,
    });
    this.zone.runOutsideAngular(() => {
      this.stickyNavSub = this.layoutNavigation.stickyNavOpen$
        .pipe(distinctUntilChanged())
        .subscribe((isStickyNavOpen) => {
          this.zone.run(() => {
            this.isStickyNavOpen = isStickyNavOpen;
          });
        });
    });
    if (environment.isRToasterTagEnabled) this.injectRToasterScript();
    this.router.events.subscribe((event: NavigationEvent) => {
      this.navigationInterceptor(event);
    });
    this.viewportSub = this.viewport.viewport$.subscribe((info) => {
      this.window.viewPortStatus = info;
      this.window.dispatchEvent(
        new CustomEvent('viewPortStatus', { detail: info })
      );
    });
    this.spinnerSub = this.spinner.spinner$.subscribe((state: boolean) => {
      this.window.spinnerState = state;
      this.window.dispatchEvent(
        new CustomEvent('spinnerState', {
          detail: {
            spinner: state,
          },
        })
      );
    });
    const browser = this.browserDetection.detect() || {};
    this.window.browserDetection = browser;
    if (!isEmpty(browser) && browser.name) {
      this.renderer2.addClass(this.window.document.body, browser.name);
      this.renderer2.addClass(
        this.window.document.body,
        browser.name + get(browser, 'version', '').split('.')[0]
      );
    }
    const mBox = get(this.appDefaults, 'ADOBE_MBOX');
    if (mBox) {
      this.adobeTarget.setMbox(mBox);
    }
    this.pictureService.isRenderingComplete$.next(true);
    this.injectScript();
    this.injectNoScriptAtBody();
    const windowObj: any = window;
    this.digitalDataObj = windowObj['digitalData'] ?? {};
    this.digitalDataLayerObj = windowObj['dataLayer'] ?? [];
    const index = this.digitalDataLayerObj.findIndex((obj: any) =>
      Object.keys(obj).includes('page')
    );
    if (index !== -1) {
      windowObj['dataLayer'][index] = this.digitalDataObj;
    }
    this.globalNav.dataReadyNotification$.subscribe((data: any) => {
      this.navRegularData = JSON.stringify(data);
      // this.globalNav.init();
      this.cdRef.detectChanges();
    });
    this.globalFooter.dataReadyNotification$.subscribe((data: any) => {
      this.footerRegularData = JSON.stringify(data);
      this.cdRef.detectChanges();
    });
  }
  loadGdsNav(): void {
    this.globalNav.init();
    this.globalFooter.init();
  }
  private navigationInterceptor(event: NavigationEvent): void {
    if (this.route?.snapshot?.queryParams?.purchaseHistory === 'true') {
      this.addHistoryRoute();
    }
    if (event instanceof NavigationStart) {
      this.spinner.show();
      this.window.appRouteStatus = 'NavigationStart';
      this.common.initAnalytics();
    }
    if (event instanceof NavigationEnd) {
      this.spinner.hide();
      this.window.appRouteStatus = 'NavigationEnd';
      if (
        !this.common.isDDLEnabled() &&
        !this.adobeTargetDisabled &&
        (!get(event, 'id') || event.id > 1)
      ) {
        this.adobeTarget.getABTestOffer();
      }
      this.window.dispatchEvent(
        new CustomEvent('NavigationEnd', { detail: event })
      );
      this.common.scrollTop();
    }
    if (event instanceof NavigationCancel) {
      this.spinner.hide();
      this.window.appRouteStatus = 'NavigationCancel';
    }
    if (event instanceof NavigationError) {
      this.spinner.hide();
      this.window.appRouteStatus = 'NavigationError';
    }
    this.window.appRouteStatusEvent = event;
  }

  injectScript(): void {
    const gtm = environment.gtmId;
    this.gtmUrl = `https://www.googletagmanager.com/gtag/js?id=${gtm}`;
    const script = this.renderer2.createElement('script');
    script.src = this.gtmUrl;
    this.renderer2.appendChild(this.document.head, script);
    this.appDataLayerService.digitalDataEnabled = true;
    this.initDigitalData();
    this.appDataLayerService.initDigitalDataLayer();
  }

  injectNoScriptAtBody(): void {
    const noscript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    const gtm = environment.gtmId;
    this.gtmUrl = `https://www.googletagmanager.com/ns.html?id=${gtm}`;
    iframe.setAttribute('src', this.gtmUrl);
    iframe.setAttribute('height', '0');
    iframe.setAttribute('width', '0');
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';
    noscript.appendChild(iframe);
    document.body.prepend(noscript);
  }

  private initDigitalData(): void {
    const digitalDataEnabled = get(appDefault, 'DIGITAL_DATA_ENABLED', true);
    if (digitalDataEnabled) {
      this.digitalDataService.init();
    }
  }

  ngOnDestroy(): void {
    this.stickyNavSub.unsubscribe();
    this.viewportSub.unsubscribe();
    this.spinnerSub.unsubscribe();
  }

  fetchProgressBar(): void {
    this.common?.getProgressBarData()?.subscribe(
      (res: any) => {
        this.common.setIconArrayData(res?.[0]?.data?.progressBar);
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  injectRToasterScript(): void {
    const rToasterId = environment.rToasterTagId;
    this.loadScript(`//js.rtoaster.jp/${rToasterId}/rt.js`).then(() => {
      this.loadScript('//js.rtoaster.jp/Rtoaster.Popup.js').then(() => {});
    });
  }

  loadScript(src: any, async = true, charset = 'utf-8'): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const script = this.renderer2.createElement('script');
      script.type = 'text/javascript';
      script.async = async;
      script.charset = charset;
      script.src = src;
      script.onload = (): any => {
        resolve();
      };
      script.onerror = (error: any): any => {
        reject(error);
      };

      this.renderer2.appendChild(
        this.document.getElementsByTagName('head')[0],
        script
      );
    });
  }

  callTrackAndRecommend(): void {
    if (this.window['Rtoaster']) {
      const RToasterTrackScript = this.document.createElement('script');
      RToasterTrackScript.type = 'text/javascript';
      RToasterTrackScript.text = 'Rtoaster.trackAndRecommend()';
      this.renderer2.appendChild(
        this.document.getElementsByTagName('head')[0],
        RToasterTrackScript
      );
    }
  }

  addHistoryRoute(): void {
    this.document.getElementById('order_history')?.focus();
  }

  componentLoaded(event: any): any {
    if (!this.cartItemsLoaded) {
      this.cartItemsLoaded = true;
      this.common.getCartItems();
    }
    if (event?.target?.data?.classList === 'gds-logo') {
      const clickedElement = event.target as HTMLElement;
      this.renderer2.listen(clickedElement, 'click', (_event) => {
        window.open('https://www.usj.co.jp/web/ja/jp', '_self');
      });
    }
  }

  globalNavButtonElementClicked(event: any): any {
    const button: any = event?.target?.data?.button;
    if (button && button?.description === 'cart') {
      this.router.navigateByUrl(this.appRoutes.CART);
    }
  }
}
