// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import { IEnvironment } from './environment.type';

export const environment: IEnvironment = {
  accountAppPath: '/account',
  adobeAutosearchUrl:
    'https://content.atomz.com/autocomplete/sp10/04/f8/c6/?query=',
  adobeBodyScript:
    'if(_satellite && _satellite.pageBottom) { _satellite.pageBottom();}',
  adobeHeaderScript:
    '//assets.adobedtm.com/a2ef59fba8e9/4e6345f9deef/launch-EN03a862c6c9ea457fa9d3e09989343813-development.min.js',
  adobeMbox: 'target-global-mbox',
  adobeTargetDisabled: false,
  appTheme: 'white-background',
  baseRedirectBypass: true,
  bingSiteVerificationKey: '695C8D6126AB21D375502ECC658DADD5',
  cardinalScriptUrl:
    'https://songbirdstag.cardinalcommerce.com/cardinalcruise/v1/songbird.js',
  defaultCountry: 'jp',
  defaultLanguage: 'ja',
  defaultTheme: 'assets/_themes.css',
  denaliUrl: 'https://tickets2.universalorlando.com',
  digitalDataAdblockerEnabled: false,
  digitalDataAngularVersion: '8.3',
  digitalDataAppVersion: '1.0',
  digitalDataDestination: 'UO',
  digitalDataEnabled: true,
  digitalDataEnvironment: 'STG',
  digitalDataFlashVersion: '',
  digitalDataSegment: 'B2C',
  digitalDataSite: 'UO',
  digitalDataTridionVersion: 'site9',
  diningReservationsAppPath: 'https://stage.universalorlando.com/dining/',
  facebookLikeUrl: 'https://www.facebook.com/UniversalOrlandoResort/',
  fontCssUrl: 'assets/fonts/_fonts.css',
  iconCssUrl: 'assets/icons/_font-face.css',
  geo: {
    apiUrl: '/api/geo/location',
    service:
      'https://geo-location-prod.use.ucdp.net/GeoDirectoryServer-7.2.0/v1/ipinfo/',
    disabled: false,
    location: {
      akamai: false,
    },
    region: {
      primary: '',
    },
  },
  googleCalendarApiKey: '',
  globalNavigationEnabled: true,
  googleSiteVerificationKey: 'ammiNOphmIzZZZCvL2SUchOTkKnjM1ydny4KqL_ERW4',
  grecaptchaApiUrl: '//www.google.com/recaptcha/api.js',
  grecaptchaSiteKey: '6LfEHyYTAAAAAGZu9fnOAAkhJhB5_Ylz6VNNMQZe',
  grecaptchaEnabled: false,
  grecaptchaTestResponse: 'bc1f106a-adb7-45e8-b314-0a61febd5555',
  hmacKey: 'k4pK5M66Tk3NAtwLjfgxX2NPDQPuboVX18cZ4tifn8fYjjsE0iP5SjmwhnSRjPj0',
  layoutHeader: '_LayoutHeader.html',
  mobileServicesShowtimesEndpoint: '/web/api/minion/showtimes',
  mobileServicesVenueEndpoints: '/web/api/minion/venues',
  oauthBaseUrl: 'https://api-stg.universalparks.com',
  oauthClientId: '197c85fe-a937-4a0e-a394-9fa5cff4ab8f',
  oauthClientKeyID: 'yV2aR5kR1xG7bJ8yG4rU2oP4oX6xU3qR3sK6cI4sM0tB8xA5sR',
  oauthAppFlowPath: '/guestauth/app/token',
  oauthAppPasswordFlowPath: '/guestauth/user/token',
  oauthAppSourceId: '1002062',
  oidcEnabled: true,
  oidcClientId: 'bf35ef93-9a0a-4aab-885a-c3a5e1a56710',
  oidcClientKeyID: 'rTMdMbZ5vKwDsUsgR3sMdwwV2RDdGHk3p3HIp6KdXIblUmGY3T',
  oidcBaseUrl: 'https://api-stg.usj.co.jp',
  oidcNodeApiBaseUrl: '/account/api/oidc',
  oidcRedirectEnabled: true,
  oidcRedirectApi: '/oidc/connect/authorize',
  oidcSignoffApi: '/oidc/connect/endsession',
  oidcAppFlowPath: '/oidc/connect/token',
  oidcAppPasswordFlowPath: '/oidc/connect/token',
  oneTrustDomainScript: '',
  oneTrustEnabled: false,
  oneTrustModalScript: '',
  oneTrustHash: '',
  oneTrustCrossorigin: 'anonymous',
  oneTrustPrivacyScript: '',
  oneTrustPrivacyIds: 'dummy',
  oneTrustPrivacyGeo: 'US',
  oneTrustPrivacyLSPA: 'false',
  coveo: {
    enabled: true,
    scriptSearchUrl:
      'https://static.cloud.coveo.com/searchui/v2.10094/3/js/CoveoJsSearch.Lazy.min.js',
    scriptSearchHash:
      'sha512-0TQxte3BQBFm6LMBjWvy7BSL3OfnzWeZan5bGVd2B/If/9wIYL7ipAWDRtI8HsM08TwP0c6DYnhfy9cOGe56hg==',
    scriptTemplateUrl:
      'https://static.cloud.coveo.com/searchui/v2.10094/3/js/templates/templates.j',
    scriptTemplateHash:
      'sha512-eiSn4qRsVzoRB0c52nRdn8mWn04bANr/LV3rXslk9b+PLN73I6aod28OyEbPTn7iMETKQHlSPESNdosKL/Cp8w==',
    css: 'https://static.cloud.coveo.com/searchui/v2.10094/3/css/CoveoFullSearch.min.css',
    cssHash:
      'sha512-TBs03XI7M4g24z1tWm/81JbvLmC37V35QJxFCayy4NH0yDZ46d/zYlgO9QxLPBu2DmKEsKQxtqfgOU/GfvIRwA==',
    crossOrigin: 'anonymous',
    accessKeyID: 'xxf6d142fb-2bea-4a24-9123-26d2cccb0163',
    organizationId: 'nbcuniversalnonproduction10wz5488n',
    scriptLocale:
      'https://static.cloud.coveo.com/searchui/v2.10094/3/js/cultures/es-es.js',
    scriptLocaleHash:
      'sha512-qFjeMB7hdq+a25Bedf+7iUMML1MhsfdSdng2Te69YmR/et5iIxZlK381Wey234INGujG1xN0oGLo4LdzX5A/7g==',
  },
  production: false,
  basic_version: false,
  resortAreaCode: 'UOR',
  resortName: 'UO',
  seoDescription:
    'The various areas with different expressions are a different world full of excitement. A theme park with world-class entertainment that can be enjoyed by both children and adults, including exciting rides themed after Hollywood blockbusters and shows starring popular characters.',
  seoImage:
    'https://www.universalorlando.com/tridiondata/k2/en/us/files/Images/universal-orlando-resort-color-logo-b.png',
  seoFacebookSiteName: 'Universal Orlando Resort',
  seoTitle: 'Universal Studios Japan | USJ',
  seoTwitterSiteName: '@UniversalORL',
  sorryPagePath: '/oops-sorry',
  tridionSite: 'usj',
  tridionBaseUrl: '/tridiondata',
  tripDetailLink: '/web-packages/en/us/shopping-cart/{requestId}',
  uprvUrl:
    'https://res.alpha.universalorlandovacations.com/Booking/Retrieval/Default.aspx?confirmationNo=Reservation_ID&id_token_hint=token_id',
  upsellPackages: 'H572, H573',
  wcsStoreId: '10851',
  webAppPath: 'https://stg.usj.co.jp/web/',
  webstoreAppPath: 'https://stage.universalorlando.com/web-store',
  webstoreJunction: 'https://stage.universalorlando.com/',
  weatherApi: '',
  timeZone: 'America/New_York',
  uniWebServiceApiKey: 'WebApp',
  COMPLETE_AP: 'https://apass-p-st.usj.co.jp/app2/usjPassEntry.do',
  commerceApiUrl: 'https://comm-api-stg.usj.co.jp/',
  commerceClientId: 'mobile_android',
  commerceClientKeyID: 'secret',
  wtsHostBaseUrl: 'https://shop-stg.usj.co.jp/',
  entitlementBaseUrl: 'https://usj-guest-entitlements-stg.use.ucdp.net',
  gtmId: 'GTM-PPL35D4L',
  rToasterTagId: 'RTA-5bb9-58ac0fdff500',
  isRToasterTagEnabled: true,
  cookieSubDomain: '.usj.co.jp',
  contentApiBaseUrl: 'https://api-usj-stg.universalparks.com',
  crewToolsApi: 'https://usj-dynamics-api-stg.use.ucdp.net/USJCrewToolSTG',
  contentLoginClientID: '5208e9d3-44d3-449d-9502-bee157f94fc8',
  contentLoginClientKeyID: 'wG5oJ8iV3dM0nX5vT2uK7nM6cT8rY1oI2mJ4qR6gD1qD8uR5jJ',
  language_dropdown: true,
  version_dropdown: true,
  contentIds: {
    GDS_FOOTER_CONTENT_ID: 'GDS-Footer-Utility-USJ-ACCOUNT',
    TRIDION_PUBLICATION: 'usj',
    GDS_GLOBAL_NAV_UTILITY_BAR_CONTENT_ID: 'GDS-Main-Nav-Utility-USJ-Commerce',
    GDS_GLOBAL_NAV_MEGA_NAV_CONTENT_ID:
      'gds-global-nav-usj-mega-nav-main-account',
  },
  inquiryToken: 'https://api-stg.usj.co.jp',
  oidcInquiryClientId: 'bf35ef93-9a0a-4aab-885a-c3a5e1a56710',
  oidcInquiryClientKeyID: 'rTMdMbZ5vKwDsUsgR3sMdwwV2RDdGHk3p3HIp6KdXIblUmGY3T',
  prependImageBaseUrl: false,
};
